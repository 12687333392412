<template>
  <div>
    <v-card class="mx-auto" flat v-if="header && currentMonth">
      <v-card-title>
        <div class="text-lg-h6">
          <v-icon class="mr-2">calendar_month</v-icon>
          Monthly Timesheet for
          <span class="primary--text">{{ user.name }}</span>
        </div>
        <v-spacer />
        <v-chip
          class="primary lighten-4 primary--text text--darken-4 mx-2"
          v-if="period"
          >Current Period: {{ period.name }} - {{ period.startingDate }}</v-chip
        >
        <v-chip v-else>No period set</v-chip>
        <v-chip class="primary lighten-4 primary--text text--darken-4"
          >No. {{ user.employee }}</v-chip
        >
      </v-card-title>
      <v-card class="mx-auto ml-4" flat>
        <v-chip-group
          active-class="teal lighten-4 teal--text text--darken-4"
          :value="currentMonthIndex"
        >
          <v-slide-item v-for="(item, i) in months" :key="i">
            <v-chip
              filter
              outlined
              :disabled="isMonthDisabled(item.text)"
              @click="selectMonth(item.text)"
            >
              {{ item.text }}
            </v-chip>
          </v-slide-item>
        </v-chip-group>
      </v-card>
      <v-row>
        <v-col cols="12" md="4" sm="12">
          <v-list dense>
            <v-list-item-group dense>
              <v-list-item dense>
                <v-list-item-title> Staff Name: </v-list-item-title>
                <v-list-item-title>
                  {{ user.name }} - {{ user.email }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-title> month: </v-list-item-title>
                <v-list-item-title>
                  {{ header ? header.month : "" }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-divider vertical inset />
        <v-col cols="12" md="4" sm="12">
          <v-list dense>
            <v-list-item-group>
              <v-list-item dense>
                <v-list-item-title> Productive Hours: </v-list-item-title>
                <v-list-item-title>
                  {{ header ? header.productiveHours : "" }} Hour(s)
                </v-list-item-title>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-title> Recorderd Hours: </v-list-item-title>
                <v-list-item-title>
                  {{ header ? header.recorderdHours : "" }} Hour(s)
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-divider vertical inset />
        <v-col cols="12" md="4" sm="12">
          <v-list dense>
            <v-list-item-group>
              <v-list-item dense>
                <v-list-item-title> Required Hours: </v-list-item-title>
                <v-list-item-title>
                  {{ header ? header.requiredHours : "" }} Hour(s)
                </v-list-item-title>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-title> status: </v-list-item-title>
                <v-list-item-title>
                  <v-chip
                    :class="
                      header
                        ? `${statusColor(header.approvalStatus).color}`
                        : 'grey'
                    "
                    >{{ header ? header.approvalStatus : "" }}</v-chip
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>

      <v-card class="mx-auto mt-n6" flat>
        <v-card-title>
          <span class="text-lg-5">Timesheet Entries</span>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            dense
            hide-details
            outlined
            style="width: 200px"
            class="mx-2"
          />
          <v-spacer />
          <v-btn
            class="text-capitalize primary mx-2"
            elevation="0"
            link
            :disabled="!header"
            @click="getTimesheetReport()"
          >
            <v-icon class="mr-2">summarize</v-icon>
            Timesheet Report
          </v-btn>
          <v-btn
            class="text-capitalize white--text teal mx-2"
            elevation="0"
            link
            :disabled="!header"
            @click="sendForApproval()"
            v-if="header && header.approvalStatus === 'Open'"
          >
            <v-icon class="mr-2">schedule_send</v-icon>
            Send for Approval
          </v-btn>
          <v-btn
            class="text-capitalize deep-orange white--text mx-2"
            elevation="0"
            link
            :disabled="!header"
            v-if="
              header &&
              (header.approvalStatus === 'Rejected' ||
                header.approvalStatus === 'Declined')
            "
            @click="reOpenTimeSheet()"
          >
            <v-icon class="mr-2">cancel_schedule_send</v-icon>
            Reopen Timesheet
          </v-btn>
          <v-btn
            class="text-capitalize deep-orange white--text mx-2"
            elevation="0"
            link
            :disabled="!header"
            v-if="header && header.approvalStatus === 'Pending Approval'"
            @click="cancelApproval()"
          >
            <v-icon class="mr-2">cancel_schedule_send</v-icon>
            Cancel Approval
          </v-btn>

          <v-btn
            class="text-capitalize primary"
            elevation="0"
            link
            :disabled="!header || header.approvalStatus !== 'Open'"
            @click="gotToCard()"
          >
            <v-icon class="mr-2">add</v-icon>
            Add Activities
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" md="8" sm="12" offset="4" class="mt-n4">
              <v-tabs v-model="selectedWeek" grow centered dense>
                <v-tab v-for="(item, i) in calenderWeeks" :key="i" dense>{{
                  item.name
                }}</v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <v-data-table
                disable-pagination
                hide-default-footer
                :headers="entryHeaders"
                :items="timeSheetLinesDisplay"
              >
                <!--eslint-disable-next-line -->
                <template v-slot:item.activitiesCode="{ item }">
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 200px"
                    >{{ item.activitiesCode }}</span
                  >
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" md="8" sm="12">
              <v-tabs-items v-model="selectedWeek">
                <v-tab-item v-for="(tab, t) in calenderWeeks" :key="t">
                  <div class="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th v-for="(item, i) in hoursSelectDates" :key="i">
                            <v-card
                              v-if="item.week === tab.code"
                              flat
                              tile
                              :color="
                                item.nonworking
                                  ? 'grey lighten-3'
                                  : 'cyan lighten-4'
                              "
                              class="d-flex flex-column align-end mx-auto px-2 hoursCard"
                            >
                              <div class="text-caption mr-1">
                                {{ getDayInt(item.periodStart) }}
                              </div>
                              <div class="text-caption mt-n2 border--bottom">
                                {{ getDay(item.periodStart) }}
                              </div>
                            </v-card>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, i) in timeSheetLinesDisplay"
                          :key="i"
                          style="height: 6vh"
                        >
                          <td
                            v-for="(hours, h) in item.calenderDays"
                            :key="h"
                            style="border-bottom: 1px solid #000"
                          >
                            <v-menu
                              v-model="hours.menu"
                              :close-on-content-click="false"
                              :nudge-width="50"
                              open-on-hover
                              offset-y
                              v-if="tab.code === hours.week"
                              :nudge-left="50"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  v-on="on"
                                  v-bind="attrs"
                                  class="text-caption mt-3 align-self-center primary--text"
                                >
                                  {{ hours.hours }}
                                </div>
                              </template>
                              <v-card
                                class="mx-auto d-flex py-2 px-2"
                                v-if="hours.line !== null"
                              >
                                <div class="text-caption text-capitalize mx-2">
                                  Entry:
                                  {{
                                    hours.line === null
                                      ? ""
                                      : hours.line.entryNo
                                  }}
                                </div>
                                <v-btn
                                  color="primary"
                                  elevation="0"
                                  @click="editEntry(hours.line)"
                                  x-small
                                  class="text-capitalize text-body-2"
                                >
                                  Edit
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </v-card>
                            </v-menu>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="paginationLength"
              :disabled="!header"
              :total-visible="0"
            ></v-pagination>
          </div>
        </v-card-text>
        <v-card-text v-if="header && header.approvalStatus !== 'Open'">
          <ApprovalEntries :approvalEntries="approvalEntries" />
        </v-card-text>
      </v-card>
    </v-card>
    <v-card class="mx-auto" flat elevation="1" v-else>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-skeleton-loader type="list-item-two-line" />
          <v-skeleton-loader type="list-item-two-line" />
          <v-skeleton-loader type="list-item-two-line" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" sm="12">
          <v-skeleton-loader type="table" />
        </v-col>
        <v-col cols="12" md="8" sm="12">
          <v-skeleton-loader type="list-item-two-line" />
          <v-skeleton-loader type="list-item-two-line" />
          <v-skeleton-loader type="list-item-two-line" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import moment from "moment";
import { helper } from "@kinetics254/cassandra-base/utilities";
import ApprovalEntries from "./ApprovalEntries.vue";
export default {
  name: "TimesheetList",
  components: {
    ApprovalEntries,
  },
  data: function () {
    return {
      page: 1,
      timeSheetLines: [],
      selectedWeek: 0,
      search: "",
      year: new Date().getFullYear(),
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Timesheet/getHeaders", to.params.month);
      v.$store.dispatch("Timesheet/getCalender", {
        month: to.params.month,
        year: v.year,
      });
      v.$store.dispatch("Timesheet/getPeriod", to.params.month);
      v.$store.dispatch("Timesheet/getProjects");
      v.$store.dispatch("Timesheet/getIsAppliedLeave");
    });
  },
  mounted() {
    Event.$on("getApprovalEntries", () => {
      this.getApprovalEntries();
    });
    Event.$on("refetchTimesheets", () => {
      this.refetchTimesheets();
    });
  },
  computed: {
    user: function () {
      return this.$store.getters["auth/user"];
    },
    isAppliedLeave: function () {
      return this.$store.getters["Timesheet/timesheetGetters"](
        "isAppliedLeave"
      );
    },
    timeSheetLinesDisplay: function () {
      return this.header
        ? this.search.length > 0
          ? this.header.timeSheetLines
              .slice((this.page - 1) * 5, this.page * 5)
              .filter((item) => {
                return (
                  item.activitiesCode
                    .toLowerCase()
                    .includes(this.search.toLowerCase()) ||
                  item.projectCode
                    .toLowerCase()
                    .includes(this.search.toLowerCase()) ||
                  item.description
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
                );
              })
          : this.header.timeSheetLines.slice((this.page - 1) * 5, this.page * 5)
        : [];
    },
    approvalEntries: function () {
      return this.$store.getters["Timesheet/timesheetGetters"](
        "approvalEntries"
      );
    },
    paginationLength: function () {
      return this.header ? Math.ceil(this.header.timeSheetLines.length / 5) : 0;
    },
    totalPaginationVisible() {
      if (this.header) {
        return this.header.timeSheetLines
          ? Math.ceil(this.header.timeSheetLines.length / 5) === 1
            ? 0
            : Math.ceil(this.header.timeSheetLines.length / 5)
          : 0;
      } else {
        return 0;
      }
    },
    timesheet: function () {
      return this.$store.getters["Timesheet/timesheetGetters"]("timesheet");
    },
    header: function () {
      const header = this.timesheet ? this.timesheet : {};
      header.timeSheetLines = header.timeSheetLines
        ? header.timeSheetLines.map((el) => {
            const project = this.getProject(el.projectCode);
            const activity = this.getActivity(
              el.projectCode,
              el.activitiesCode
            );
            el.projectCode = project ? project.description : el.projectCode;
            el.activitiesCode = activity
              ? activity.description
              : el.activitiesCode;
            el.calenderDays = el.calenderDays.map((day) => {
              return {
                ...day,
                menu: false,
                week: Math.ceil(moment(day.date).date() / 7),
              };
            });
            return {
              ...el,
            };
          })
        : [];
      return header;
    },
    months: function () {
      return [
        { text: "January" },
        { text: "February" },
        { text: "March" },
        { text: "April" },
        { text: "May" },
        { text: "June" },
        { text: "July" },
        { text: "August" },
        { text: "September" },
        { text: "October" },
        { text: "November" },
        { text: "December" },
      ];
    },
    period: function () {
      return this.$store.getters["Timesheet/timesheetGetters"]("period");
    },
    currentMonth: function () {
      return this.$route.params.month;
    },
    currentMonthIndex: function () {
      return this.months.findIndex((el) => el.text === this.currentMonth);
    },
    defaultColor() {
      return {
        icon_color: "orange darken-1",
        icon: "rate_review",
        color: "orange darken-1",
      };
    },
    calender: function () {
      return this.$store.getters["Timesheet/timesheetGetters"]("calender");
    },
    entryHeaders: function () {
      return [
        {
          text: "Project",
          value: "projectCode",
          align: "left",
          sortable: false,
        },
        {
          text: "Activity",
          value: "activitiesCode",
          align: "left",
          sortable: false,
        },
      ];
    },
    calenderWeeks: function () {
      return [
        {
          code: 1,
          name: "Week 1",
        },
        {
          code: 2,
          name: "Week 2",
        },
        {
          code: 3,
          name: "Week 3",
        },
        {
          code: 4,
          name: "Week 4",
        },
        {
          code: 5,
          name: "Week 5",
        },
      ];
    },
    hoursSelectDates: function () {
      return this.calender && this.period
        ? this.calender
            .filter((el) => el.month === this.currentMonth)
            .map((el) => {
              el.week = Math.ceil(moment(el.periodStart).date() / 7);
              return {
                ...el,
              };
            })
        : [];
    },
  },
  methods: {
    getDayInt: function (date) {
      return moment(date).format("D");
    },
    getDay: function (date) {
      return moment(date).format("ddd");
    },
    getActivity: function (project, activity) {
      return this.getProject(project)
        ? this.getProject(project).projectActivities.find(
            (el) => el.activitiesCode === activity
          )
        : undefined;
    },
    getProject: function (code) {
      return this.$store.getters["Timesheet/timesheetGetters"]("projects").find(
        (el) => el.code === code
      );
    },
    gotToCard: function () {
      this.$router.push({
        name: "TimesheetCard",
        params: {
          month: this.header.month,
        },
      });
    },
    sendForApproval: function () {
      this.$confirm.show({
        title: "Send For Approval",
        text: "Are you sure you want to send for approval?",
        onConfirm: () => {
          this.$store.dispatch(
            "Timesheet/sendForApproval",
            this.removeLinesFromPayload()
          );
        },
      });
    },
    removeLinesFromPayload: function () {
      const data = { ...this.header };
      delete data.timeSheetLines;
      return data;
    },
    cancelApproval: function () {
      this.$confirm.show({
        title: "Cancel Approval",
        text: "Are you sure you want to cancel approval?",
        onConfirm: () => {
          this.$store.dispatch(
            "Timesheet/cancelApproval",
            this.removeLinesFromPayload()
          );
        },
      });
    },
    editEntry: function (item) {
      this.$router.replace({
        name: "TimesheetCard",
        params: {
          month: this.header.month,
        },
        query: {
          projectActivity: item.projectActivity,
          project: item.project,
          entryNo: item.entryNo,
        },
      });
    },
    getTimesheetReport: function () {
      this.$router.push({
        name: "TimesheetReport",
        params: {
          period: this.period.startingDate,
        },
      });
    },
    statusColor: function (status) {
      return status
        ? helper.getColor(status)
          ? helper.getColor(status)
          : this.defaultColor
        : this.defaultColor;
    },
    isMonthDisabled: function (month) {
      const months = this.months.slice(0, new Date().getMonth() + 1);
      return !months.find((el) => el.text === month);
    },
    reOpenTimeSheet: function () {
      this.$confirm.show({
        title: "Re-Open Timesheet",
        text: "Are you sure you want to re-open timesheet?",
        onConfirm: () => {
          this.$store.dispatch("Timesheet/reOpenTimeSheet", {
            employeeCode: this.user.employee,
            periodCode: this.header.periodCode,
          });
        },
      });
    },
    getApprovalEntries: function () {
      this.$store.dispatch("Timesheet/getApprovalEntries", {
        timesheetDocumetNo: this.header.periodCode,
      });
    },
    selectMonth: function (month) {
      this.$router.push({
        name: "TimesheetList",
        params: {
          month: month,
        },
      });
      this.refetchTimesheets();
    },
    refetchTimesheets: function () {
      this.$store.dispatch("Timesheet/getHeaders", this.$route.params.month);
      this.$store.dispatch("Timesheet/getCalender", {
        month: this.$route.params.month,
        year: this.year,
      });
      this.$store.dispatch("Timesheet/getPeriod", this.$route.params.month);
    },
  },
  watch: {
    periods: {
      handler: function (val) {
        this.periodMonth = this.months.findIndex(
          (el) => el.text === this.period.name
        );
      },
      deep: true,
    },
  },
};
</script>
<style>
@import url("../styles/styles.css");
</style>
